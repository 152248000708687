<template>
  <div :style="'width:' + width">
    <el-radio-group
      v-if="type === 'basic' || type === 'button'"
      @change="$emit('input', localModel)"
      :disabled="readonly"
      :name="name"
      :style="this.CSS"
      :size="size"
      v-model="localModel"
    >
      <component
        v-for="(item, index) in options"
        :key="index"
        :label="item.value"
        :is="type === 'basic' ? 'el-radio' : 'el-radio-button'"
      >{{ item.name }}</component>
    </el-radio-group>
    <el-radio-group v-else-if="type === 'lights'" :disabled="readonly" :name="name" :style="this.CSS + ';width:100%'">
      <template v-for="(item, index) in options">
        <el-tooltip
          v-if="item.tooltip"
          class="item"
          effect="dark"
          :key="index"
          :content="item.tooltip"
        >
          <el-button
            circle
            size="mini"
            :style="{background: item.color}"
            @click="localModel = item.value, $emit('input', item.value)"
            :label="item.value"
          ></el-button>
        </el-tooltip>
        <el-button
          v-else
          circle
          size="mini"
          :style="{background: item.color}"
          :key="index"
          @click="localModel = item.value, $emit('input', item.value)"
          :label="item.value"
        ></el-button>
      </template>
    </el-radio-group>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import legacyMixin from '@/components/InterfaceEditor/components/legacyMixin'
export default {
  mixins: [mixin, legacyMixin],
  props: {
    model: {
      frozen: true
    },
    defaultValue: {
      type: String,
      description: 'По умолчанию'
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    type: {
      type: String,
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'lights', name: 'Светофор' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },
    size: {
      type: String,
      description: 'Размер'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    options: {
      type: Array,
      default () {
        return [{ name: 'test', value: 'test' }]
      },
      editor: 'Options'
    }
  },
  computed: {
    lights () {
      let lights = false
      this.options.forEach(element => {
        if (element.color) lights = true
      })
      return lights
    }
  },
  data () {
    return {
      localModel: this.$attrs.value || this.defaultValue
    }
  },
  mounted () {
    if (this.localModel) {
      let parsed = JSON.parse(this.localModel)
      if (parsed instanceof Array && parsed.length > 0) {
        this.localModel = parsed[0].id + ''
      }
    }
    this.$emit('input', this.localModel)
  },
  name: 'a-radio'
}
</script>

<style scoped>
</style>
